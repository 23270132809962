import facebook from 'assets/img/facebook.png';
import { urls } from 'common/urls';

const { contact, largeFamily, subjects } = urls;

const year = new Date().getFullYear();

export const texts = {
  footerTop: [
    {
      id: 1,
      columns: 3,
      links: [
        {
          id: 1,
          text: 'Matematyka',
          url: `/${subjects.root}/${subjects.math}`,
        },
        {
          id: 2,
          text: 'Język polski',
          url: `/${subjects.root}/${subjects.polish}`,
        },
        // {
        //   id: 3,
        //   text: 'Historia',
        //   url: `/${subjects.root}/${subjects.history}`,
        // },
        {
          id: 4,
          text: 'Informatyka',
          url: `/${subjects.root}/${subjects.informatics}`,
        },
      ],
      title: 'Przedmioty',
    },
    {
      id: 2,
      columns: 3,
      links: [
        {
          id: 1,
          text: '1 klasa',
          url: '/klasa-1',
        },
        {
          id: 2,
          text: '2 klasa',
          url: '/klasa-2',
        },
        {
          id: 3,
          text: '3 klasa',
          url: '/klasa-3',
        },
        {
          id: 4,
          text: '4 klasa',
          url: '/klasa-4',
        },
        {
          id: 5,
          text: '5 klasa',
          url: '/klasa-5',
        },
        {
          id: 6,
          text: '6 klasa',
          url: '/klasa-6',
        },
        {
          id: 7,
          text: '7 klasa',
          url: '/klasa-7',
        },
        {
          id: 8,
          text: '8 klasa',
          url: '/klasa-8',
        },
      ],
      title: 'Poziomy nauczania',
    },
    {
      id: 4,
      columns: 3,
      links: [
        {
          id: 1,
          text: 'Dowiedz się więcej',
          url: `/${largeFamily.root}`,
        },
      ],
      title: 'Karta Dużej Rodziny',
    },
    {
      id: 5,
      columns: 3,
      links: [
        {
          id: 1,
          text: 'Formularz kontaktowy',
          url: `/${contact}`,
        },
        {
          id: 2,
          text: 'tel. 58 764 61 61',
          url: '',
        },
        {
          id: 3,
          img: {
            alt: 'eduGry – facebook',
            src: facebook,
          },
          url: 'https://www.facebook.com/eduGryGWO/',
        },
      ],
      title: 'Kontakt',
    },
  ],
  footerBottom: {
    company: {
      address: 'al. Grunwaldzka 50A, 80-241 Gdańsk',
      name: 'Gdańskie Wydawnictwo Oświatowe spółka z ograniczoną odpowiedzialnością sp. k.',
    },
    copy: `copyright &copy; Gdańskie Wydawnictwo Oświatowe, ${year}`,
  },
};
