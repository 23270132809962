import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import App from 'App';
import { GOOGLE_ANALYTICS_ID } from 'common/constants';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_ID } from 'common/constants';
import Cookies from "js-cookie";

const { REACT_APP_GOOGLE_ANALYTICS } = process.env;
const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID
};
const consentValues = [2, 3];

const getCookieConsent = () => {
  const cookie = Cookies.get('gwo-cookie-consent');

  if (cookie) {
    const consent = JSON.parse(cookie);
    return consent?.performance || 0;
  }
  return 0;
}

if (REACT_APP_GOOGLE_ANALYTICS !== undefined && consentValues.includes(getCookieConsent())) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<App />, document.getElementById('root'));

if (consentValues.includes(getCookieConsent())) {
  TagManager.initialize(tagManagerArgs);
}

document.addEventListener('gwocookies:cookie-save', () => {
  window.location.reload();
})
